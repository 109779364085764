import { useState, useEffect } from "react";
import React from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
} from "firebase/firestore";


function CarBox({ car }) {
  if (!car) {
    return null;
  }

  return (
    <div className="box-cars">
      {/* car */}
      <div className="pick-car">
        {/* Assuming 'car.img' is a URL, you might want to use an <img> tag here */}
        <img
          src={car.img}
          alt="car_img"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
      {/* description */}
      <div className="pick-description">
        <div className="pick-description__price">
          <span>${car.price}</span>/ rent per day
        </div>
        <div className="pick-description__table">

        <div className="pick-description__table__col">
            <span>Mark</span>
            <span>{car.mark}</span>
          </div>

          <div className="pick-description__table__col">
            <span>Model</span>
            <span>{car.model}</span>
          </div>
          
          <div className="pick-description__table__col">
            <span>Type</span>
            <span>{car.air}</span>
          </div>

          <div className="pick-description__table__col">
            <span>Year</span>
            <span>{car.year}</span>
          </div>
          <div className="pick-description__table__col">
            <span>Doors</span>
            <span>{car.doors}</span>
          </div>
          
          <div className="pick-description__table__col">
            <span>Transmission</span>
            <span>{car.transmission}</span>
          </div>
          <div className="pick-description__table__col">
            <span>Fuel</span>
            <span>{car.fuel}</span>
          </div>
        </div>
        {/* btn cta */}
        <a className="cta-btn" href="#booking-section">
          Reserve Now
        </a>
      </div>
    </div>
  );
}

export default CarBox;
