import React, { useState, useEffect } from "react";

function Bannerz() {
  

  const images = [

    {
      url: "https://glamofnyc.com/wp-content/uploads/2021/04/Antigua-Shirley-View_-1440x960.jpg",

    },

    {
      url: "https://c.wallhere.com/photos/b9/6a/Antigua_and_Barbuda_island_sea_bay_nature_city_sky_sunset-2201249.jpg!d",
    },
    {
      url: "https://milesopedia.com/wp-content/uploads/2023/01/Hell-s-Gate-Island-Antigua-Barbuda-1024x768.jpg",
    },


    {
      url: "https://cdn1.matadornetwork.com/blogs/1/2021/03/costa-rica-beaches-playa-grande-1.jpg",
    },
    {
      url: "https://images.ctfassets.net/wv75stsetqy3/7jJ4xHBXMvgvPOtBM4EmzI/c9fa2014e6a0d055f0c9eed3456ebb17/Costa_Rica_Featured_Image.jpg?q=60&fit=fill&fm=webp",
    },


   
    
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  return (
    <>
      <section
        className="banner-section"
        style={{
          //backgroundColor: '#00b712',
         // backgroundImage: 'linear-gradient(95deg, #00690a 0%, #3a9e0e 79%)'
         position: 'relative',
         height:'600px'
        }}
      >
         <img alt="Background Shape" style={bgImageStyle}  src={images[currentIndex].url}
            />
      

  
    </section>
  </>
);
}

export default Bannerz;