import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

// Set the app element to avoid screen reader accessibility issues
// Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%', marginTop:'60px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    zIndex: 9999, // High z-index value,
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 9998, // High z-index value, slightly lower than content
  },
};

const PromotionsModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 3800); // 7 seconds

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Promotions"
    >
      <h2 style={{textAlign:'center', color:'orangered'}}>Promotions</h2>
      <ul style={{fontSize:'1.5rem', marginTop:'12px' }}>
      {/* 
      <li style={{marginTop:'8px' }}><p style={{fontWeight:'bold'}}>General Discount:</p>15% off: 7 days or more car rental</li> 
      <li style={{marginTop:'8px' }}><p style={{fontWeight:'bold'}}>Hotel Keyonna customer:</p>10% off: 3 days or more car rental</li> 
      <li style={{marginTop:'8px'}}><p style={{fontWeight:'bold'}}>Hotel Coco’s  customer:</p>10% off: 3 days or more car rental</li>
      <li style={{marginTop:'8px'}}><p style={{fontWeight:'bold'}}>Hotel Tamarind Hills customer:</p>15% off: 7 days or more car rental</li>
      <li style={{marginTop:'8px', color:'red'}}><p style={{fontWeight:'bold'}}>Promotion Condition:</p>Promotion is valid only on online booking!</li>
      */}


      <li style={{marginTop:'8px' }}><p style={{fontWeight:'bold'}}>Hotel Keyonna customer:</p>15% off: 7 days or more car rental</li> 
      <li style={{marginTop:'8px'}}><p style={{fontWeight:'bold'}}>Hotel Coco’s  customer:</p>15% off: 7 days or more car rental</li>
      <li style={{marginTop:'8px'}}><p style={{fontWeight:'bold'}}>Hotel Tamarind Hills customer:</p>15% off: 7 days or more car rental</li>
      <li style={{marginTop:'8px' }}><p style={{fontWeight:'bold'}}>Other Customers:</p>10% off: 7 days or more car rental</li> 
      <li style={{marginTop:'8px', color:'red'}}><p style={{fontWeight:'bold'}}>Promotion Condition:</p>Promotion is valid only on online booking!</li>
      </ul>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button onClick={closeModal} style={{ marginTop: '20px', padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: 'white', fontSize:'1.4rem', fontWeight:'bold' }}>
          Click To Close
        </button>
      </div>
    </Modal>
  );
};

export default PromotionsModal;
