import Img2 from "../images/testimonials/pfp1.jpg";
import Img3 from "../images/testimonials/pfp2.jpg";

function Testimonials() {
  return (
    <>
      <section className="testimonials-section" id="reviews-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Reviewed by People</h4>
              <h2>Client's Testimonials</h2>
              <p>
              Discover the positive impact through client testimonials. Our reliable and affordable car rentals in the Antigua have earned rave reviews. Join satisfied customers for a seamless and memorable travel experience. Choose Us for your next journey. We provide Jolly's Harbour Car rental, Bolands car rental, Antigua rent a car.
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                "Great Hire Company. We hired two cars off them over 3 days. The cars we clean, new and well maintained.
No rip offs, the price they quote is the price you pay.
Really nice chap who dropped off the cars for us.
I would not hesitate to use them again. Great."
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img2} alt="user_img" />
                    <span>
                    <h4>Mikey T</h4>
                      <p>USA</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "Excellent service,  brilliant communication. Very flexible as we had to change our dates at literally the last minute. The car was great, spotless, efficient. Great price.
I would highly recommend pineapple car rental."
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img3} alt="user_img" />
                    <span>
                    <h4>Nathan Crown</h4>
                      <p>Canada</p>
                    </span>
                  </div>
                </div>
              </div>



              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
