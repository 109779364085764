import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";
import { Helmet } from "react-helmet";

function TestimonialsPage() {


  return (
    <>

        <Helmet>
        <title>
        Testimonials - 
        </title>

        <meta
          name="description"
          content="Explore customer testimonials and reviews for Hernandez Car Rental. Find the best car rental deals in Surf Citi, El Salvador. Affordable, reliable, and top-quality car renting services. Your ultimate car renting experience awaits with Hernandez Car Rental!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>


      <section className="testimonial-page">
        <HeroPages name="Testimonials" />
        <Testimonials />
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:'#7CFC00'}}>+679 934 1249</h3> 
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TestimonialsPage;
