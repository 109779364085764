import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.jpg";
import Box2 from "../images/chooseUs/icon2.jpg";
import Box3 from "../images/chooseUs/icon3.jpg";
import Box4 from "../images/chooseUs/icon4.jpg";

function ChooseUs() {
  return (
    <>
      <section className="choose-section" id="choosing-section" style={{ marginTop: "-180px" }}>
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Choosing Us Is Wise</h4>
                <h2>Best value to money deals to cater your trip</h2>
                <p>
                Embark on a seamless car rental Antigua experience, offering exceptional service in areas like Jolly Harbour, Commercial Centre, and Bolands. With Pineapple Car Rental, you can unlock unbeatable deals on reliable vehicles that cater to your travel needs. Whether you're arriving at V.C. Bird International Airport or exploring popular destinations like Sandals Grande Antigua and Nelson's Dockyard, we ensure affordability without compromising quality. Our car rental Jolly Harbour Antigua services give you the freedom to explore at your own pace. For those flying in, our car rental Antigua airport services are perfect for a hassle-free start to your trip. Whether you need jolly harbour car rental or car hire Antigua, we are your go-to destination for budget-friendly, reliable vehicles. Book with us today and make the most of your journey through Antigua & Barbuda. Choose Pineapple rentals now for a smooth rental experience.
                </p>

                <h4>Requirements & Terms</h4>
                <p>
                  We are 100% Locally Owned business, and we can work according
                  to your requirements. We will assist you in any way possible.
                  We take all major debit/credit cards You can pay us whenever
                  you are ready. Also, you can pay us via online banking, TT,
                  Western Union, MoneyGram or others if it is easier/cheaper for
                  you.
                </p>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>All Vehicle Types</h4>
                    <p>
                      With all variety of vehicle types, take your driving
                      experience to next level.
                    </p>
                  </div>
                </div>

                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>All Inclusive Pricing</h4>
                    <p>
                      Get everything you need in one convenient, transparent
                      price with our all-inclusive pricing policy.
                    </p>
                  </div>
                </div>

                <div className="text-container__right__box">
                  {" "}
                  <img src={Box4} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Road Side Assistance</h4>
                    <p>
                      We provide prompt roadside assistance to our customers in
                      need.
                    </p>
                  </div>
                </div>

                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Quick Customer Support</h4>
                    <p>
                      We love our customers, get lightening support from our
                      customer support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
