import { useState } from "react";
import Card1 from "../images/about/pay2.png";
import Card2 from "../images/about/pay1.png";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section" style={{marginTop:'-150px'}}>
        <div className="container" >
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Car Rental Booking Process
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: '#f99d45',
                    backgroundImage: 'linear-gradient(265deg, #f99d45 60%, #FF9800 85%)',
                  }}
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Do you provide long term rentals?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Yes, we arrange short term, long term rentals. For rental hires simply fill in the booking form above and we will get back to you via email.
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: '#f99d45',
                    backgroundImage: 'linear-gradient(45deg, #f99d45 60%, #FF9800 85%)',
                  }}
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. How can I contact you?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  We can be contacted via whatsapp, email or even a txt
                  message. Discover the perfect car rental deals in Antigua with us
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: '#f99d45',
                    backgroundImage: 'linear-gradient(235deg, #f99d45 60%, #FF9800 85%)',
                  }}
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. When was this company established?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                 Pineapple Car Rental was established 10 years ago and is since servicing
                  customers in Antigua with professionalism.
                </div>
              </div>
            

           

           



            </div>
          </div>

          <div style={{ marginTop: "35px" }}>
            <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
              We Accept All Payment Methods
            </h2>
            <div className="row" style={{marginTop:'7px'}}>
              <div className="col-6">
                <img src={Card1} style={{width:'90%', height:'100%'}} />
              </div>

              <div className="col-6">
                <img src={Card2}  style={{width:'90%', height:'100%'}} />
              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  );
}

export default Faq;
