
function Footer() {
  function handleSubscribe() {
    alert("Thanks for subscribing!!!");
  }

  return (
    <>
      <footer style={{height:'120px', marginTop:'-90px'}}>
        <div className="container" >
          <div className="footer-content">
            <ul className="footer-content__2">
              <li>Contact Us</li>

              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+1 268-771-9422">&#x260E; +1 268-771-9422</a>
              </li>


              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a
                  href="mailto: 
                  pineapplecarsrental@gmail.com"
                >
                  &#9993; pineapplecarsrental@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/zoXRctaVxiwHwBYS8"
                  // href="https://jephunneh.com/"
                >
                  &#128205; Jolly's Harbour, Commercial Centre, Bolands, Antigua
                </a>
              </li>


            </ul>

            <ul className="footer-content__1">
              <li>
                <span style={{ color: "orange" }}>Pineapple Car Rental</span> 
               
              </li>

              <li style={{ fontSize: "13px" }}>
                We offer a range of vehicles for car rental with great customer support. Call us for car rental jolly harbour Antigua.
              
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span>
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech"
                >
                  <span style={{ color: "orange" }}>Qasim</span>
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                {/*<a   href="https://maps.app.goo.gl/gyo3oLjVkVEJomSi8"> 
                  Our Location
                </a> */}
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
                <a href="/">Mobile</a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://adminpineapplecarrental.netlify.app"
                  //
                >
                  Admin &#128187;
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 5:00PM</li>
              <li>Sun: Closed</li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
