import { useState, useEffect } from "react";
import CarBox from "./CarBox";
import { Link } from "react-router-dom";
import { db } from "../components/firebase.js";
import { getDocs, collection } from "firebase/firestore";
import BookCar from "./BookCar.jsx";
import pineapple from "../images/hero/pineapple.png";
import ReactDOM from 'react-dom';
//import PromotionsModal from './promo.jsx';


function PickCar() {
  const [cars, setCars] = useState([]);

  /*
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  */


  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
  
        // Sort the data by term position and length of the value
        const sortedData = data.sort((a, b) => {
          // Use default values if termPosition or value is missing
          const termPositionA = a.termPosition !== undefined ? a.termPosition : Infinity;
          const termPositionB = b.termPosition !== undefined ? b.termPosition : Infinity;
          const valueA = a.value !== undefined ? a.value : "";
          const valueB = b.value !== undefined ? b.value : "";
  
          // Compare term position first
          if (termPositionA !== termPositionB) {
            return termPositionA - termPositionB; // Ascending order of term position
          }
          // If term positions are equal, compare by the length of the value
          return valueA.length - valueB.length; // Shorter values come first
        });
  
        setCars(sortedData); // Update the state with sorted data
        // setCarTypes(sortedData.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);


  const [debouncedReserveNow, setDebouncedReserveNow] = useState(null);

  const handleReserveNow = () => {
    // Your logic for handling Reserve Now
    window.location.href = "#booking-section"; // Example: change URL
  };

  const debounce = (func, delay) => {
    if (debouncedReserveNow) {
      clearTimeout(debouncedReserveNow);
    }

    const timeout = setTimeout(() => {
      func();
      setDebouncedReserveNow(null);
    }, delay);

    setDebouncedReserveNow(timeout);
  };


  const [selectedCar, setSelectedCar] = useState(null);

  const handleClick = (car) => {
   // setSelectedCar('');
    setSelectedCar(car.name);
    debounce(handleReserveNow, 400);
  };

  const bgImageStyle = {
   // position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
   // height: '50%',  
  };
  


  return (
    <section id="pick-section" className="pick-section" style={{marginTop:'-100px'}} >
      <div className="container">
        <div className="pick-container__title" style={{marginBottom:'-100px'}}>
          <h2>Rental Fleet</h2>
          <p>
          We offer a range of vehicles for all your car rental needs. We have the perfect car to meet your needs.
          </p>
        </div>

        <div className="models-div">
       
          {cars.map((car) => (
            <div>
            <img src={pineapple} alt="Background Shape" style={bgImageStyle} />
            <div
             key={car.id}
          //  className="models-div__box"
              style={{
                backgroundColor: "#f9f9f9",
                border: "2px solid #ddd",
                borderRadius: "8px",
                transition: "transform 0.3s ease",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                position: "relative",
                overflow: "hidden",
                cursor: "pointer", zIndex:2, marginTop:'-190px'
               
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
               
              <div className="models-div__box__img">
                <img
                  src={car.img}
                  alt="car_img"
                  style={{
                   
                    height: "180px",
                    width: "90%",
                    // objectFit: "cover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    "@media(max-width: 500px)": {
                      // Media query for screens less than 500px width
                      height: "auto", // Allow the height to adjust according to the aspect ratio
                      width: "100%", // Take full width of the container
                    
                    },
                  }}
                />
                <div
                  className="models-div__box__img-overlay"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {car.name}
                </div>
              </div>
              <div
                className="models-div__box__descr"
                style={{ padding: "15px" }}
              >
                <div
                  className="models-div__box__descr__price"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4
                    style={{
                      margin: "5px 10px 5px 0",
                      color: "#333",
                      fontSize: "21px",
                    }}
                  >
                    ${car.price2}
                  </h4>
                  <p style={{ margin: "0", fontSize: "14px", color: "#666" }}>
                    per day
                  </p>
                </div>

                <div className="models-div__box__descr__details">
                  <span style={{ fontSize: "11px", margin: "2px" }}>
                  Minimum Rental 3 days
                  </span>
                 
                 
{/*

 <span style={{ fontSize: "11px", margin: "2px" }}>
                    ---
                  </span>
                  <span style={{ fontSize: "11px", margin: "2px" }}>
                     (Week): ${car.price3} Discounted
                  </span>
*/}
                </div>

                <div className="models-div__box__descr__details">
                  <span style={{ fontSize: "11px", margin: "2px", color:'blue' }}>
                   Insurance per day: ${car.insurance}/Day
                  </span>
                </div>

                <div className="models-div__box__descr__details">
                  <span style={{ fontSize: "11px", margin: "2px" }}>
                    &#x2022; {car.transmission}
                  </span>
                  <span style={{ fontSize: "11px", margin: "2px" }}>
                    &#x2022; {car.fuel} Passenger
                  </span>
                  <span style={{ fontSize: "11px", margin: "2px" }}>
                    &#x2022; {car.air}
                  </span>
                </div>
                <button
                  className="models-div__box__descr__btn"
                  style={{
                    height: "36px",
                    width: "100%",
                    backgroundColor: '#f99d45',
                    backgroundImage: 'linear-gradient(215deg, #f99d45 60%, #FF9800 85%)',
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    color: "white",
                    fontWeight: "bold",
                    marginTop: "8px",
                    transition: "background-color 0.3s ease",
                    fontSize: "15px",
                  }}
                  onClick={() => handleClick(car)}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#20a4f3")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "#182b3a")
                  }
                >
                  Reserve Now
                </button>
              </div>
              </div>
            </div>
          ))}
        </div>
       

      </div>

      { <BookCar carName={selectedCar} />}

    </section>
  );
}

export default PickCar;
