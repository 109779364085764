
import BgShape from "../images/hero/bookbg1.jpg";
function Banner() {


  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };


  return (
    <>
      <section
        className="banner-section"
        style={{
         // backgroundColor: "#00b712",
          //backgroundImage: "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)"
          position: 'relative'
        }}
      >
  <img src={BgShape} alt="Background Shape" style={bgImageStyle} />

        <div className="container">
          <div className="row">
            <div className="col-12" style={{ height: "220px" }}>
              <div className="banner-content">
                <div className="banner-content__text" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.29)' , width:'100%'
              }}  >
                  <h2>Best Car Rental In Bolands, Antigua</h2>
                  
                </div>
              </div>
            </div>

            <div className="col-12" style={{ height: "270px" }}>
              <iframe
            //src="https://www.google.com/maps/embed?pb=!4m6!3m5!1s0x8c1293f1af8e3e2f:0x52fb8a6780ae9509!8m2!3d17.0661826!4d-61.8737877!16s%2Fg%2F11n__ylk2v!5m2!1sen!2sus"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.749539212057!2d-61.8737877!3d17.0661826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c1293f1af8e3e2f%3A0x52fb8a6780ae9509!2sPineapple%20Car%20Rentals!5e0!3m2!1sen!2sus!4v1685589984306!5m2!1sen!2sus"
            
            width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="eager"
              ></iframe>       
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
