import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/logo/logo.png";


function Navbar() {
  const navigate = useNavigate();
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const bookBtn = () => {
    // Check if the current location is already the home page
    if (window.location.pathname !== "/") {
      // Navigate to the home page
      navigate("/");
    }

    // After navigating to the home page, scroll to the pick-section
    setTimeout(() => {
      document
        .querySelector("#pick-section")
        .scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const aboutBtn = () => {
    // Check if the current location is already the home page
    if (window.location.pathname !== "/") {
      // Navigate to the home page
      navigate("/");
    }

    // After navigating to the home page, scroll to the pick-section
    setTimeout(() => {
      document
        .querySelector("#choosing-section")
        .scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const reviewsBtn = () => {
    // Check if the current location is already the home page
    if (window.location.pathname !== "/") {
      // Navigate to the home page
      navigate("/");
    }

    // After navigating to the home page, scroll to the pick-section
    setTimeout(() => {
      document
        .querySelector("#reviews-section")
        .scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  {/* 
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
*/}



  return (
    <div style={{ marginBottom: "35px", backgroundColor:'#00b712s'  }}>
      
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "28px",
          
         // backgroundColor: '#FFEB3B', 
         backgroundColor: '#f99d45',
           backgroundImage: 'linear-gradient(135deg, #f99d45 20%, #FF9800 80%)',
         
        
         // WebkitTextFillColor: 'transparent',
         
          marginBottom: "10px",
        }}
      >
        <a
          href="mailto:pineapplecarsrental@gmail.com
          "
          style={{
            color: "white",
            textDecoration: "none",
            marginRight: "25px",
            fontSize: "13px",
          }}
        >
          &#9993;Click Here To Email Us

        </a>
        <a
          href="tel:+1 268-771-9422
"
          style={{ color: "white", textDecoration: "none", fontSize: "13px" }}
        >
          &#x260E;+1 268-771-9422

        </a>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{
          // boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          borderBottomLeftRadius: "30px",
          borderBottomRightRadius: "30px",
          height: "104px", width: '100%',
          //position: "fixed",
          marginTop: "25px",
           backgroundColor: '#f99d45',
           backgroundImage: 'linear-gradient(135deg, #f99d45 20%, #FF9800 80%)',
        }}
      > 
        {/* */}
        <div className="container">
          {/* Logo */}

            <Link
            className="navbar-brand"
            to="/"
            onClick={() => window.scrollTo(0, 0)}
          > 
        <img
          src={Logo}
          alt="logo-img"
          style={{  height: "102px", width: "195px", backgroundColor: '#fdf4dc', // backgroundColor: '#00b712',
          borderRadius:'25px'}}
        />
     
          </Link>

          {/* Mobile Menu Toggle */}
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Links */}
          <div
            className={`collapse navbar-collapse ${navOpen ? "show" : ""}`}
            style={{ backgroundColor: navOpen ? "#FF9800" : ""   }}
          >
            <ul
              className="navbar-nav ml-auto"
              style={{ paddingTop: "15px", paddingLeft: "4px" }}
            >
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={closeNav}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    color: "#00FFFF",
                    transition: "color 0.3s",
                    marginLeft: "11px",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "white")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "#00FFFF")} // Reset color on mouse out
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                   aboutBtn();
                  closeNav();
                  }}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#00FFFF",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "white")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "#00FFFF")} // Reset color on mouse out
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    bookBtn();
                    closeNav();
                  }}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#00FFFF",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "white")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "#00FFFF")} // Reset color on mouse out
                >
                  Vehicle Models
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    reviewsBtn();
                    closeNav();
                  }}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#00FFFF",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "white")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "#00FFFF")} // Reset color on mouse out
                >
                  Testimonials
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  onClick={closeNav}
                  style={{
                    fontSize: "18px",
                    paddingLeft: "1px",
                    marginLeft: "11px",
                    color: "#00FFFF",
                    transition: "color 0.3s",
                  }}
                  onMouseOver={(e) => (e.target.style.color = "white")} // Change color on hover
                  onMouseOut={(e) => (e.target.style.color = "#00FFFF")} // Reset color on mouse out
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </nav>






    </div>
  );
}

export default Navbar;
