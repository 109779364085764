import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg1.jpg";
import HeroCar from "../images/hero/main-car.png";
import { useEffect, useState } from "react";
import whatsapp from "../images/logo/whatsapp.png";
import facebook from "../images/logo/fb.png";


function Hero() {
  const [goUp, setGoUp] = useState(false);
  //const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bookBtn = () => {
    document.querySelector('#booking-section').scrollIntoView({ behavior: 'smooth' });
  };

  const openWhatsApp = () => {
    window.open('https://wa.me/12687719422', '_blank'); 

  };

  const openfacebook = () => {
    window.open('https://www.facebook.com/profile.php?id=61565536044191&mibextid=ZbWKwL', '_blank'); 
   };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', onPageScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', onPageScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const styles = {
    btn: {
      backgroundColor: '#FF9800',
           backgroundImage: 'linear-gradient(115deg, #f99d45 40%, #FF9800 65%)',
      transition: 'box-shadow 0.3s ease-in-out',
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '14px 18px',
      margin: '4px', // add margin for spacing between buttons
      borderColor:'orange',
      borderRadius: '8px',
    },
  };


  const [slideIn, setSlideIn] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Trigger the slide-in animation after a short delay
    const delay = setTimeout(() => {
      setSlideIn(true);
    }, 850); // Adjust the delay as needed

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slideAnimation = {
    marginTop: window.innerWidth < 768 ? '140px' : '19px', // Increase marginTop for small 
    zIndex: 2,
    position: 'absolute',
    right: slideIn ? 40 : '-100%',
    transition: 'right 2.0s ease-in-out',
    width: '65rem', // Set the maximum width to 100%
    height: 'auto', // Maintain the aspect ratio
    maxWidth: window.innerWidth < 768 ? '72%' : '65%', // Set maxWidth for mobile view
    //marginTop:'25px',
   
  };
  



  const containerStyle = {
    position: 'relative', height:'730px', marginTop:'-45px'
    //backgroundImage: 'linear-gradient(315deg, #00690a 0%, #3a9e0e 74%)',
    // Adjusted the color values to create a darker gradient
    // Add any other styles you need here
  };

  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };



  return (
    <section id="home" className="banner-section" style={containerStyle}>
      <div className="container" >
      <img src={BgShape} alt="Background Shape" style={bgImageStyle} />
      
        
    
      <img src={HeroCar} alt="car-img" style={slideAnimation} />
     

      
      <div className="hero-content" style={{ position: 'relative', zIndex: 2, height:'350px' }}>

      
 
        <div className="hero-content__text" style={{marginBottom:'-35rem'}}>

          <h1 style ={{backgroundColor: 'rgba(0, 0, 0, 0.4)', marginTop:'6px', color:'whitesmoke',  borderRadius:'20px', fontSize:'4.5rem'}}>
      Best Cars For Your Journey

          </h1>
         
          <div style={{ height: '90px', width:'100%', }}>
            {windowWidth >= 1100 ? (
              <div style={styles.container}>
                <p style={{ color:'black' ,backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius:'20px'}}>
                Experience the car of your dreams with Pineapple Car Rental. Enjoy unparalleled prices, unlimited mileage.
                </p>
              </div>
            ) : null}
          </div>

          <div className="hero-content__text__btns" style={{marginTop:'22px'}} >
            <Link onClick={bookBtn} className="hero-content__text__btns__book-ride" to="/" style={styles.btn}>
              Book Car
            </Link>
            <a
              style={styles.btn}
            href="https://maps.app.goo.gl/FVFj3qx5yAWPVXEt6"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-content__text__btns__book-ride"
            >
              Location
            </a>
            <Link className="hero-content__text__btns__book-ride" to="/contact" style={styles.btn}>
              Contact Us <i className="fa-solid fa-angle-right"></i>
            </Link>
          </div>
        </div>
        
        
      

      </div>
      

      
    </div>

      {/* WhatsApp button */}


      <div
        onClick={openWhatsApp}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '118px',
          left: '1rem',
          zIndex: '1000', 
        }}
      >
        <img src={whatsapp} alt="Facebook-img" style={{ height: 65, width: 65, borderRadius:'7px' }} />
      </div>

      <div
        onClick={openfacebook}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '35px',
          left: '1rem',
          zIndex: '1000', 
        }}
      >
        <img src={facebook} alt="Facebook-img" style={{ height: 65, width: 65, borderRadius:'7px' }} />
      </div>


      {/* Scroll to top button */}
      <div style={{   backgroundColor: '#f99d45',
           backgroundImage: 'linear-gradient(275deg, #f99d45 5%, #FF9800 95%)',}} onClick={scrollToTop} className={`scroll-up ${goUp ? 'show-scroll' : ''}`}>
        <p style={{ fontSize: 22 }}>&#9650;</p>
      </div>
    </section>
  );
}

export default Hero;

const styles = {
  container: {
   // height: '110px',
    display: 'block', // Ensure it's a block element
   
  },
  // Media query to hide the container when the screen width is less than 500px
  '@media (max-width: 800px)': {
    container: {
      display: 'none',
      
    },
  },
};