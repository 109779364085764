

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'
import 'firebase/compat/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyDqc81uagT2bH1gwlTql4NLiWCJKhma92o",

  authDomain: "pineapple-car-rental.firebaseapp.com",

  projectId: "pineapple-car-rental",

  storageBucket: "pineapple-car-rental.appspot.com",

  messagingSenderId: "687426400091",

  appId: "1:687426400091:web:47f2c0bce097141641747e"

  
  };
  
  
  
let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app();
}

const db = app.firestore();


export {db} 