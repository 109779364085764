import React from 'react';

const NotFoundPage = () => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Make the container take the full height of the viewport
    textAlign: 'center',
  };

  const headingStyle = {
    fontSize: '2em',
  };

  const paragraphStyle = {
    marginTop: '10px',
    color: '#888', // Optional: Add a color to the description
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>404 - Not Found</h1>
      <p style={paragraphStyle}>Sorry, the page you are looking is not available. Please recheck URL!</p>
    </div>
  );
};

export default NotFoundPage;