import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarImg1 from "../images/cars-big/audi-box.png";
import { Link } from "react-router-dom";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { useState, useEffect } from "react";

function Models() {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="models-section">
        {/*
            <div className="models-div__box">

              <div className="models-div__box__img">
                <img src={CarImg2} alt="car_img" />
                <div className="models-div__box__descr">
                  <div className="models-div__box__descr__name-price">
                    <div className="models-div__box__descr__name-price__name">
                      <p>Golf 6</p>
                      <span>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                        <i className="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__price">
                      <h4>$37</h4>
                      <p>per day</p>
                    </div>
                  </div>
                  <div className="models-div__box__descr__name-price__details">
                    <span>
                      <i className="fa-solid fa-car-side"></i> &nbsp; VW
                    </span>
                    <span style={{ textAlign: "right" }}>
                      4/5 &nbsp; <i className="fa-solid fa-car-side"></i>
                    </span>
                    <span>
                      <i className="fa-solid fa-car-side"></i> &nbsp; Manual
                    </span>
                    <span style={{ textAlign: "right" }}>
                      Diesel &nbsp; <i className="fa-solid fa-car-side"></i>
                    </span>
                  </div>
                  <div className="models-div__box__descr__name-price__btn">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/">
                      Book Ride
                    </Link>
                  </div>
                </div>
              </div>
            </div>
  */}

        <div className="container">
          <div className="models-div">
            {cars.map((car) => (
              <div className="models-div__box">
                <div className="models-div__box__img">
                  <img src={car.img} alt="car_img" />
                  <div className="models-div__box__descr">
                    <div className="models-div__box__descr__name-price">
                      <div className="models-div__box__descr__name-price__name">
                        <p>{car.name}</p>
                        <span>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                        </span>
                      </div>
                      <div className="models-div__box__descr__name-price__price">
                        <h4>${car.price}</h4>
                        <p>per day</p>
                      </div>
                    </div>
                    <div className="models-div__box__descr__name-price__details">
                      <span>
                        <i className="fa-solid fa-car-side"></i> {car.mark}
                      </span>
                      <span style={{ textAlign: "right" }}>
                        {car.doors} <i className="fa-solid fa-car-side"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-car-side"></i>{" "}
                        {car.transmission}
                      </span>
                      <span style={{ textAlign: "right" }}>
                        {car.fuel} <i className="fa-solid fa-car-side"></i>
                      </span>
                    </div>
                    <div className="models-div__box__descr__name-price__btn">
                      <Link onClick={() => window.scrollTo(0, 0)} to="/">
                        Book Ride
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Models;

const styles = {
  pokerCardList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  pokerCard: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    margin: "10px",
    overflow: "hidden",
    width: "250px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  cardImage: {
    width: "100%",
    height: "150px",
    objectFit: "cover",
  },
  cardContent: {
    padding: "15px",
    // display: 'flex',
    //   flexDirection: 'column',
    // alignItems: 'center',
  },
  cardName: {
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: "bold",
  },
  cardPrice: {
    marginBottom: "10px",
    fontSize: "16px",
  },
  cardDetails: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    width: "100%",
  },
  cardBtn: {
    marginTop: "15px",
    textAlign: "center",
  },
};
