import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import HeroPages from "../components/HeroPages";
import React from "react";
import { db } from "../components/firebase.js";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet";


function Contact() {
  {
    
    /*
  const carDatas = [
    
  
    {
      name: "Mazda BT50",
      price: "75",
      img: 'https://carsguide-res.cloudinary.com/image/upload/f_auto,fl_lossy,q_auto,t_cg_hero_large/v1/editorial/2023-Mazda-BT-50-SP-Pro-press-image-1001x565p.jpg',
      air: "Truck",
      transmission: "Automatic",
      fuel: "2024",
    },
  
    {
      name: "Nissan Np 300",
      price: "75",
      img: 'https://res.cloudinary.com/driveau/vehicles/showrooms/models/nissan-navara.jpg',
      air: "Truck",
      transmission: "Automatic",
      fuel: "2023",
    },
  
    {
      name: "Hyundai Atoz",
      price: "35",
      img: 'https://i.pinimg.com/736x/b0/d2/de/b0d2de3ccad3d910e43cd297af31cb3f.jpg',
      air: "Compact",
      transmission: "Automatic",
      fuel: "2023",
    },

    {
      name: "Nissan Np 300 Manual",
      price: "75",
      img: 'https://carsguide-res.cloudinary.com/image/upload/f_auto,fl_lossy,q_auto,t_cg_hero_low/v1/editorial/nissan-navara-my21-index-1.png',
      air: "Compact",
      transmission: "Manual",
      fuel: "2024",
    },
  
    {
      name: "Nissan Rogue 2013",
      price: "45",
      img: 'https://www.motortrend.com/uploads/sites/10/2015/11/2013-nissan-rogue-sv-fwd-suv-angular-front.png',
      air: "Compact SUV",
      transmission: "Automatic",
      fuel: "2013",
    },
  
    {
      name: "Nissan Rogue 2016",
      price: "55",
      img: 'https://platform.cstatic-images.com/xlarge/in/v2/stock_photos/4e76df27-9d8d-4673-b870-1b76ad914ba7/0d0f491e-6cdb-4803-a30a-0495cc0f7b24.png',
       air: "Compact SUV",
      transmission: "Automatic",
      fuel: "2016",
    },
   
    {
      name: "Kia Soul",
      price: "40",
      img: 'https://cars.usnews.com/static/images/Auto/izmo/i5590/2016_kia_soul_angularfront.jpg',
       air: "Compact",
      transmission: "Automatic",
      fuel: "2016",
    },
  
    {
      name: "Nissan Rogue 2018",
      price: "55",
      img: 'https://65e81151f52e248c552b-fe74cd567ea2f1228f846834bd67571e.ssl.cf1.rackcdn.com/ldm-images/2018-Nissan-Rogue-Brilliant-Silver.png',
    
       air: "Compact SUV",
      transmission: "Automatic",
      fuel: "2018",
    },
  
  
  ];

  const addCarsToFirestore = async () => {
    const carsCollection = collection(db, 'carsdata');
  
    for (const car of carDatas) {
      try {
        await addDoc(carsCollection, car);
        console.log(`Car ${car.name} added to Firestore`);
      } catch (error) {
        console.error(`Error adding car ${car.name} to Firestore:`, error);
      }
    }
  };
  
 
    useEffect(() => {
      addCarsToFirestore();
    }, []); // This will run the function only once on component mount
  
*/
  
  }

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [question, setQuestion] = React.useState("");

  const postmsg = async () => {
    try {
      // Validate input fields
      if (!name || !email || !question) {
        window.alert("Please Fill All Fields!");
        return;
      }
  
      // Get the current date
      const currentDate = new Date();
  
      // Format the date as "YYYY-MM-DD"
      const formattedDate = currentDate.toISOString().split("T")[0];
  
      // Add a new document to the "Messages" collection with the specified fields
      await db.collection("Messages").add({
        cname: name,
        cemail: email,
        cquestion: question,
        date: formattedDate,
      });
  
      window.alert("Your message is sent!");
  
      // Reload the page
      window.location.reload();
      
      console.log("Contact Details Posted!");
    } catch (error) {
      // Log any errors to the console
      console.error(error);
      window.alert("An error occurred. Please try again later.");
    }
  };

  

  return (
    <>

     <Helmet>
        <title>
          Contact Us -  Car Rental Jolly Harbour Antigua - Car Hire Antigua Jolly Harbour
        </title>

        <meta
          name="description"
          content="Discover the budget car rental in Antigua with Pineapple car rental. Best service for car rental jolly harbour antigua. We provide car hire antigua jolly harbour, Bolands car rental, Antigua rent a car."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">


          <div className="row">
            <div className="col-md-6">
              <div className="contact-div">
                <div className="contact-div__text">
                  <a
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "green",
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://maps.app.goo.gl/FVFj3qx5yAWPVXEt6"
                  >
                    Click For Google Maps Location
                  </a>

                  <a
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                    href="tel:+1 268-771-9422"
                  >
                    &#x260E; +1 268-771-9422
                  </a>

                  <a
                    style={{ fontWeight: "bold", fontSize: "15px" }}
                    href="mailto:  pineapplecarsrental@gmail.com"
                  >
                    &#9993; pineapplecarsrental@gmail.com
                  </a>
                  <a style={{ fontWeight: "bold", fontSize: "15px" }} href="https://maps.app.goo.gl/xaYMaVSqEE8vjWXS6">
                    &#128205;   Jolly's Harbour, Commercial Centre, Bolands, Antigua 
                  </a>

                 
                  <br></br>
                  <h3>Need additional information?</h3>
                  <p>
                  Explore convenient and affordable vehicle rental in Antigua with Pineapple Car Rental. Our diverse fleet of well-maintained vehicles ensures reliable transportation solutions for your exploration needs with car rental Antigua. Whether you need car rental Jolly Harbour Antigua, jolly harbour car rental, or car rental Antigua airport, we provide seamless travel experiences. Trust Pineapple Car Rental for all your car hire Antigua needs and enjoy hassle-free transportation throughout the island. – Book now at our <a
                   //     href="https://maps.app.goo.gl/dFGEkxvgiPEXCZ6g7"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        office
                      </a>    for an unmatched journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-div__form">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    postmsg();
                  }}
                >
                  <label>
                    Full Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    placeholder='E.g: "Joe Shmoe"'
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    placeholder="youremail@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label>
                    Tell us about it <b>*</b>
                  </label>
                  <textarea
                    placeholder="Write Here.."
                    onChange={(e) => setQuestion(e.target.value)}
                  ></textarea>

                  <button type="submit" style={{backgroundColor: '#f99d45',
           borderColor:'orange'}}   >
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:'orange'}}>+1 268-771-9422</h3> 
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
